<template>
  <div
    style="
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    "
  >
    <img src="@/assets/hand_wave.gif" alt="Goodbye" />
  </div>
</template>

<script>
export default {
  name: 'Logout',
  mounted() {
    setTimeout(() => this.$store.dispatch('AuthModule/logout'), 800)
  },
  props: {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
